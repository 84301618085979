<template>
  <div class="course-detail">
    <div class="inner">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>学员详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="c-course">
        <div class="c-cover" :style="{ backgroundImage: 'url(' + +')' }"></div>
        <div class="c-name"></div>
        <div class="c-text">考试时间：2021-05-10 09:00 - 11:00</div>
        <div class="c-text">
          <span>考试时长：120分钟</span>
          <span>通过分数：60分</span>
          <span>学科：几何</span>
          <span>课程：三年级几何</span>
        </div>
        <div class="c-content">
          课程是课程本质上是一种教育性经验，是对主题产生积极影响的各种因素的总和；狭义上，课程专指学校场域中存在和生成的有助于学生积极健康发展的教育性因素以及学
          生获得的教育性经验。
        </div>
        <div class="c-more">
          <div class="c-text">报名人数：92138</div>
          <div class="c-btn">已考</div>
        </div>
      </div>
      <div class="c-table">
        <div class="header-full"><span>学员考试情况</span></div>
        <el-table class="table" :data="tableData" style="width: 100%">
          <el-table-column prop="date" label="姓名" width="180"></el-table-column>
          <el-table-column prop="name" label="手机号" width="180"></el-table-column>
          <el-table-column prop="address" label="人工阅卷"></el-table-column>
          <el-table-column prop="address" label="是否已阅"></el-table-column>
          <el-table-column prop="address" label="用时（分钟）"></el-table-column>
          <el-table-column prop="address" label="得分"></el-table-column>
          <el-table-column prop="address" label="是否通过"></el-table-column>
          <el-table-column prop="address" label="详情"></el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="c-page"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="prev, pager, next"
        :total="totalPages"
        :page-size.sync="pageSize"
        @current-change="currentChange"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 10,
      // 当前页码
      pageIndex: 1,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {}
};
</script>

<style></style>
